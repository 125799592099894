document.addEventListener('DOMContentLoaded', function () {
    jQuery('a')
        .click(function () {
            var ahref = jQuery(this).attr('href');
            if (ahref != undefined) {
                var achref = ahref.replace(/\"/g, '%22');
                jQuery.ajax({
                    type: "POST",
                    url: "/common/GoogleAnalytics/Index",
                    data: { "Href": achref },
                    dataType: "json",
                    success: function (response) {
                        if (response != null & response != '') {
                            if (typeof (ga) !== 'undefined') {
                                ga('send', 'event', 'Downloads', response.eventAction, response.requestUrl);
                                ga('send', 'pageview', { 'page': response.requestUrl });
                            }
                        }
                    },
                });
            }
        });
});

window.GetMarketoId = function() {
    const marketoIdCookieKey = '_mkto_trk';
    const marketoIdCookie = getCookie(marketoIdCookieKey);
    if (marketoIdCookie) {
        return marketoIdCookie;
    } else {
        return '(not set)';
    }
};

window.GetSfveclId = function() {
    const sfveclIdCookieKey = '_sfveclid_ga';
    const sfveclIdCookie = getCookie(sfveclIdCookieKey);
    if (sfveclIdCookie) {
        return sfveclIdCookie;
    } else {
        const veCrmCookieKey = 've-crm-id';
        const veCrmCookie = getCookie(veCrmCookieKey);
        if (veCrmCookie) {
            return veCrmCookie;
        } else {
            return '(not set)';
        }
    }
};

window.GetIdioVisitorId = function() {
    const ivCookieKey = 'iv';
    const ivCookie = getCookie(ivCookieKey);
    if (ivCookie) {
        return ivCookie;
    } else {
        return '(not set)';
    }
};

function getCookie(name) {
    const match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
    return match ? match[1] : null;
};
